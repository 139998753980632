<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <div class="cardTitle">账号列表</div>
        <el-button type="primary" @click="createUser" class="cardTitleBtn">新建账号</el-button>
        <el-button type="success" class="cardTitleBtn" @click="customExport('export')" style="margin-right:5px">导出物业经理数据</el-button>
        <el-upload :http-request="fileUpload" class="cardTitleBtn" action="" :show-file-list='false'>
          <el-button type="success">导入物业经理数据</el-button>
        </el-upload>
        <el-button type="success" class="cardTitleBtn" @click="customExport('download')" style="margin-right:5px">下载物业经理账号导入模板</el-button>
      </el-col>
      <el-col :span="24">
         <el-table :data="userList" style="width: 100%">
                <el-table-column prop="account" label="账号" sortable></el-table-column>
                <el-table-column prop="name" label="姓名" sortable></el-table-column>
                <el-table-column prop="phone" label="手机号" sortable></el-table-column>
                <el-table-column prop="roleName" label="角色" sortable></el-table-column>
                <el-table-column prop="expireDate" label="账号有效期" sortable></el-table-column>
                <el-table-column label="状态" sortable>
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.status=='正常'" type="success">{{scope.row.status}}</el-tag>
                    <el-tag v-if="scope.row.status=='已过期'" type="info">{{scope.row.status}}</el-tag>
                    <el-tag v-if="(scope.row.status).indexOf('即将')>=0" type="warning">{{scope.row.status}}</el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="updateTime" label="最近修改时间" sortable></el-table-column>
                <el-table-column prop="createTime" label="创建时间" sortable></el-table-column>
                <el-table-column fixed="right" label="操作">
                  <template slot-scope="scope">
                    <el-button
                      @click="getDetail(scope.row)"
                      type="primary"
                      size="mini"
                    >
                      编辑
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
      </el-col>
   <!-- <el-col :span="24">
          <div class="block" style="margin-top: 20px">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="recordList.nowPageNum"
              :page-sizes="[ 20, 40, 80]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="recordList.totalNum"
            >
            </el-pagination>
          </div>
        </el-col> -->
    </el-row>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
      userList:[],
    };
  },
  mounted() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getUserList()
  },  
  methods: {
    getUserList() {
      let self = this;
      self.$axios
        .get("/admin/list", {
          headers: { token: self.userInfo.token },
        })
        .then(function (response) {
          console.log('res=',JSON.stringify(response.data.data));
          console.log('res=',response.data.data);
          self.userList = response.data.data;
        })
        .catch(function (error) {
          self.$message({
            message:error.response.data.message,
            type: "warning",
          });
        });
    },
    getDetail(row) {
      let self = this;
      self.$router.push({ name: "admin_detail", query: { guid: row.guid } });
    },
    createUser() {
      let self = this;
      self.$router.push({ name: "admin_detail", query: { guid: null } });
    },
    handleSizeChange(val) {
      this.pageSize = val * 1;
      this.getUserPageNum()
    },
    handleCurrentChange(val) {
      this.getUserPageNum(val)
    },
    getUserPageNum(val) {
      this.getUserList(val);
    },
    customExport(type) {
      let url
      if(type == 'export') {
        url = this.GLOBAL.host + "/admin/download"
      } else if (type == 'download') {
        url = this.GLOBAL.host + "/admin/template/download"
      }
      var a = document.createElement("a");
      a.href = url;
      a.target = "_blank";
      a.click();
    },
    fileUpload(files) {
      let self = this;
      let form = new FormData()
      form.append('file', files.file)
      console.log(form);
      self.$axios
        .post("/admin/import", form ,{
          headers: { token: self.userInfo.token,'Content-Type':'multipart/form-data'}
        })
        .then(function(res) {
          self.$message({
            message:res.data.message,
            type: "success",
          });
        })
        .catch(function (error) {
          self.$message({
            message:error.response.data.message,
            type: "warning",
          });
        });
    }
  },
};
</script>

<style scoped>
</style>